import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0aefcd9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "gallery-box",
  ref: "gallery"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FAIcon = _resolveComponent("FAIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.startWithIndex !== null && _ctx.images)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "overlay",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }),
            _createVNode(_component_FAIcon, {
              icon: "times",
              class: "icon times",
              onClick: _ctx.close
            }, null, 8, ["onClick"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
              return (_openBlock(), _createElementBlock("img", {
                key: `${image.src}-${index}`,
                src: require(`@/assets/img/${image.src}`),
                class: _normalizeClass({
          prev: _ctx.prevIndex === index,
          active: _ctx.imageIndex === index,
          next: _ctx.nextIndex === index,
        })
              }, null, 10, _hoisted_2))
            }), 128)),
            _createVNode(_component_FAIcon, {
              icon: "chevron-left",
              class: "chevron left",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectImage('left')))
            }),
            _createVNode(_component_FAIcon, {
              icon: "chevron-right",
              class: "chevron right",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectImage('right')))
            })
          ], 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}