export default {
  message: `We build products that revolutionize the gaming industry on a 
    global level. Spectacular success of the product and a clear vision for 
    the future has taken us to achieving amazing things in record times. 
    We have all the freedom to create, explore and innovate - truly be the 
    pioneers of the gaming software industry!`,
  perks: [
    {
      icon: 'real_experts',
      title: 'Work alongside real experts',
    },
    {
      icon: 'start_up',
      title: 'Exciting growing start-up atmosphere',
    },
    {
      icon: 'own_ideas',
      title: 'Pitch your own business ideas',
    },
    {
      icon: 'schedule',
      title: 'Make your own schedule',
    },
    {
      icon: 'lithuania',
      title: 'Work from anywhere',
    },
    {
      icon: 'offices',
      title: 'Cozy offices in Vilnius & Kaunas',
    },
    {
      icon: 'training',
      title: 'Trainings, courses & conferences',
    },
    {
      icon: 'parties',
      title: 'Regular team buildings & parties!',
    },
    {
      icon: 'snacks',
      title: 'Kitchen full of snacks & drinks',
    },
    {
      icon: 'merch',
      title: 'Awesome merch as a welcome pack',
    },
  ],
  hr: [
    {
      image: 'toma_web',
      name: 'Toma',
      email: 'toma.viciune@adv.bet',
      position: 'Head of HR',
      linkedin: 'https://www.linkedin.com/in/toma-viciune/',
    },
    {
      image: 'asta_web',
      name: 'Asta',
      email: 'asta.prochorovaite@adv.bet',
      position: 'HR Manager',
      linkedin: 'https://www.linkedin.com/in/asta-prochorovait%C4%97/',
    },
  ],
  technologies: [
    {
      icon: 'go',
      title: 'Go Language',
    },
    {
      icon: 'mariaDB',
      title: 'MariaDB',
    },
    {
      icon: 'influxDB',
      title: 'InfluxDB',
    },
    {
      icon: 'TiDB',
      title: 'TiDB',
    },
    {
      icon: 'RabbitMQ',
      title: 'RabbitMQ',
    },
    {
      icon: 'js',
      title: 'JavaScript',
    },
    {
      icon: 'ts',
      title: 'TypeScript',
    },
    {
      icon: 'vue',
      title: 'Vue.js',
    },
    {
      icon: 'react',
      title: 'React',
    },
    {
      icon: 'angular',
      title: 'Angular',
    },
  ],
};
