interface IPositions {
  startX: number;
  endX: number;
}

type Callback = (side: string) => void; // eslint-disable-line

const handleGesture = (positions: IPositions, cb: Callback): void => {
  const diff = Math.abs(positions.endX - positions.startX);

  if (diff < 20) {
    return;
  }

  if (positions.endX < positions.startX) {
    cb('right');
  }

  if (positions.endX > positions.startX) {
    cb('left');
  }
};

export const addSideSwipeHandler = (
  element: HTMLElement,
  cb: Callback,
): string => {
  const positions = {
    startX: 0,
    endX: 0,
  };
  element.addEventListener('touchstart', (event) => {
    positions.startX = event.changedTouches[0].screenX;
  });

  element.addEventListener('touchend', (event) => {
    positions.endX = event.changedTouches[0].screenX;
    handleGesture(positions, cb);
  }, false);
  return '';
};

export const removeSideSwipeHandler = (
  element: HTMLElement,
  cb: Callback,
): void => {
  const positions = {
    startX: 0,
    endX: 0,
  };

  element.addEventListener('touchstart', (event) => {
    positions.startX = event.changedTouches[0].screenX;
  });

  element.removeEventListener('touchend', (event) => {
    positions.endX = event.changedTouches[0].screenX;
    handleGesture(positions, cb);
  }, false);
};
