import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50b47e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "slideshow",
  ref: "slide"
}
const _hoisted_2 = {
  key: 0,
  class: "slide-view"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "container slide-content" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = {
  key: 0,
  class: "dots"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.slide)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide, (frame, slideIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: frame.image,
              class: _normalizeClass(["slide", [_ctx.pageName, {
          active: _ctx.slidePositions.active === slideIndex,
          center: frame.center,
          prev: _ctx.slidePositions.prev === slideIndex,
          next: _ctx.slidePositions.next === slideIndex,
          link: frame.link,
        }]]),
              onClick: ($event: any) => (_ctx.linkMe(frame.link))
            }, [
              _createElementVNode("img", {
                src: require(`@/assets/img/slides/${frame.image}`)
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(frame.title, (textLine, textIndex) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: `${textLine.text}-${textIndex}`,
                      class: _normalizeClass({
                color: textLine.color,
                inline: textLine.inline,
              })
                    }, _toDisplayString(textLine.text), 3))
                  }), 128))
                ])
              ])
            ], 10, _hoisted_3))
          }), 128)),
          (_ctx.slide.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide, (_, dotIndex) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: `dot-${dotIndex}`,
                    class: _normalizeClass(["dot", {active: _ctx.slidePositions.active === dotIndex}]),
                    onClick: ($event: any) => (_ctx.selectSlide(dotIndex))
                  }, null, 10, _hoisted_8))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 512))
}