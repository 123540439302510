import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import axios from 'axios';
import { IState } from './IState';

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<IState>> = Symbol(undefined);

export const store = createStore<IState>({
  state: {
    positions: [],
  },
  mutations: {
    updatePositions(state, positions) {
      state.positions = positions;
    },
  },
  actions: {
    getPositions({ commit }) {
      axios.get('./jobs021024.json')
        .then((r) => {
          commit('updatePositions', r.data.positions);
        });
    },
  },
  modules: {
  },
});

// Definitions taken from:
// https://next.vuex.vuejs.org/guide/typescript-support.html#simplifying-usestore-usage
export function useStore(): Store<IState> {
  return baseUseStore(key);
}
