
import { defineComponent } from 'vue';

// Slider component
import SliderTop from '@/components/global/SliderTop.vue';

export default defineComponent({
  name: 'OurSolutions',
  components: {
    SliderTop,
  },
});
