
import { defineComponent } from 'vue';

// Component
import GalleryBox from '@/components/global/GalleryBox.vue';

export default defineComponent({
  name: 'ExhibitionGallery',
  components: {
    GalleryBox,
  },
  data() {
    return {
      imageIndex: null,
      images: [
        {
          src: 'exhibitions/gallery/1.jpeg',
          thumb: 'exhibitions/gallery/1_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/2.jpeg',
          thumb: 'exhibitions/gallery/2_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/3.jpg',
          thumb: 'exhibitions/gallery/3_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/4.jpg',
          thumb: 'exhibitions/gallery/4_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/5.jpeg',
          thumb: 'exhibitions/gallery/5_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/6.jpg',
          thumb: 'exhibitions/gallery/6_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/7.jpg',
          thumb: 'exhibitions/gallery/7_thumb.jpg',
        },
        {
          src: 'exhibitions/gallery/8.jpg',
          thumb: 'exhibitions/gallery/8_thumb.jpg',
        },
      ],
    };
  },
});
