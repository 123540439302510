import { createApp } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight, faPaperPlane, faTimes, faChevronDown, faEllipsisH, faChevronRight, faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { store, key } from '@/store';
import App from './App.vue';
import router from './router';

library.add(
  faArrowRight,
  faPaperPlane,
  faTimes,
  faChevronDown,
  faEllipsisH,
  faChevronRight,
  faChevronLeft,
);

const app = createApp(App)
  .use(store, key)
  .use(router)
  .component('FAIcon', FontAwesomeIcon);

app.mount('#app');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.store = store;
