interface ISlidesText {
  text: string;
  color?: boolean;
  inline?: boolean;
}

export interface ISlide {
  title: ISlidesText[];
  link?: string;
  image: string;
  center?: boolean;
}

export type AllowedSlides = 'home' | 'about' | 'career' | 'products' | 'solutions' | 'position' | '';

interface ISlides {
  [x: string]: ISlide[];
}

export const SlideList = {
  /*
    Slide structure
    title - list of blocks that will be joined for text. Each element is in new line
      text - part of text
      color - (optional) colors it green
      inline - make this element inline. Note - two close elements needs to be inline to show them in single line
    link - where to redirect when clicking on button
    image - slide image
    center - (optional) for element to be centered
  */
  home: [
    {
      title: [
        { text: 'Kick-Ass', color: true },
        { text: 'Betting', inline: true },
        { text: 'Solution!', inline: true },
      ],
      link: '/#our-solutions',
      image: 'home/1.jpg',
    },
    // {
    //   title: [
    //     { text: 'Grow Your' },
    //     { text: 'iGaming', color: true },
    //     { text: 'Business!' },
    //   ],
    //   link: '/#our-solutions',
    //   image: 'home/2.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Professional Software' },
    //     { text: 'For ', inline: true },
    //     { text: 'Bookmakers!', inline: true, color: true },
    //   ],
    //   link: '/#our-solutions',
    //   image: 'home/3.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Professional' },
    //     { text: 'Sportsbook Software', color: true },
    //     { text: 'For Market Leaders!' },
    //   ],
    //   link: '/solutions/#white-label',
    //   image: 'home/4.jpg',
    // },
  ],
  solutions: [
    {
      title: [
        { text: 'Fully Customisable' },
        { text: 'White Label', inline: true, color: true },
        { text: '!', inline: true },
      ],
      link: '/solutions/#white-label',
      image: 'solutions/1.jpg',
    },
    // {
    //   title: [
    //     { text: 'Worldwide Servers' },
    //     { text: 'for', inline: true },
    //     { text: 'High-Stability', inline: true, color: true },
    //     { text: 'and', inline: true },
    //     { text: 'Performance!', inline: true, color: true },
    //   ],
    //   link: '/solutions/#white-label',
    //   image: 'solutions/2.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Integrated Payment' },
    //     { text: 'Gateways!', color: true },
    //   ],
    //   link: '/solutions/#white-label',
    //   image: 'solutions/3.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Professional' },
    //     { text: 'Sportsbook Software', color: true },
    //     { text: 'For Market Leaders!' },
    //   ],
    //   link: '/solutions/#white-label',
    //   image: 'solutions/4.jpg',
    //   center: true,
    // },
  ],
  products: [
    {
      title: [
        { text: 'Upgrade', inline: true, color: true },
        { text: 'Your', inline: true },
        { text: 'Betting Shop!' },
      ],
      link: '/products/#desired-products',
      image: 'products/1.jpg',
    },
    // {
    //   title: [
    //     { text: 'Offer', inline: true },
    //     { text: 'Exclusive Products', inline: true, color: true },
    //     { text: 'and Betting Options!' },
    //   ],
    //   link: '/products/#desired-products',
    //   image: 'products/2.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Upgrade Your Shops With' },
    //     { text: 'Popular', inline: true, color: true },
    //     { text: 'Products', inline: true },
    //   ],
    //   link: '/products/#desired-products',
    //   image: 'products/3.jpg',
    //   center: true,
    // },
    // {
    //   title: [
    //     { text: 'Amaze Your Players' },
    //     { text: 'With a New', inline: true },
    //     { text: 'Modern', inline: true, color: true },
    //     { text: 'Look!', inline: true },
    //   ],
    //   link: '/products/#desired-products',
    //   image: 'products/4.jpg',
    //   center: true,
    // },
  ],
  about: [
    {
      title: [
        { text: 'Innovation', inline: true, color: true },
        { text: '&', inline: true },
        { text: 'Technology', inline: true, color: true },
        { text: 'Is Our DNA!' },
      ],
      link: '/about/#what-makes-us',
      image: 'about/1.jpg',
      center: true,
    },
    // {
    //   title: [
    //     { text: 'Intense Growth' },
    //     { text: 'Beyond', color: true },
    //     { text: 'The Benchmarks!' },
    //   ],
    //   link: '/about/#what-makes-us',
    //   image: 'about/2.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Reliable and Secure' },
    //     { text: 'Without Excuses!', color: true },
    //   ],
    //   link: '/about/#what-makes-us',
    //   image: 'about/3.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Modern', inline: true, color: true },
    //     { text: 'and', inline: true },
    //     { text: 'Flexible', inline: true, color: true },
    //     { text: 'Approach To Work!' },
    //   ],
    //   link: '/about/#what-makes-us',
    //   image: 'about/4.jpg',
    // },
  ],
  career: [
    {
      title: [
        { text: 'Join Our', inline: true },
        { text: 'Awesome', inline: true, color: true },
        { text: 'Gang', inline: true },
        { text: 'Today!' },
      ],
      link: '/career/#career-message',
      image: 'career/5.jpg',
      center: true,
    },
    // {
    //   title: [
    //     { text: 'Become Part', color: true },
    //     { text: 'Of Something Great!' },
    //   ],
    //   link: '/career/#positions',
    //   image: 'career/2.jpg',
    //   center: true,
    // },
    // {
    //   title: [
    //     { text: 'Join Our' },
    //     { text: 'Crazy Team', inline: true },
    //     { text: 'Today!', color: true, inline: true },
    //   ],
    //   link: '/career/#career-message',
    //   image: 'career/3.jpg',
    // },
    // {
    //   title: [
    //     { text: 'Fully Remote Work Conditions' },
    //     { text: 'Tailored to', inline: true },
    //     { text: 'Your Lifestyle!', inline: true, color: true },
    //   ],
    //   link: '/career/#positions',
    //   image: 'career/4.jpg',
    //   center: true,
    // },
  ],
  position: [
    {
      title: [],
      image: 'position/slide.png',
      center: true,
    },
  ],
} as ISlides;
