import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OurCulture = _resolveComponent("OurCulture")!
  const _component_AboutPosition = _resolveComponent("AboutPosition")!
  const _component_Colleague = _resolveComponent("Colleague")!
  const _component_PositionPerks = _resolveComponent("PositionPerks")!
  const _component_PositionForm = _resolveComponent("PositionForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_OurCulture),
    (_ctx.selectedPosition)
      ? (_openBlock(), _createBlock(_component_AboutPosition, {
          key: 0,
          responsabilities: _ctx.selectedPosition.responsabilities,
          requirements: _ctx.selectedPosition.requirements,
          additional: _ctx.selectedPosition.additional || [],
          salary: _ctx.selectedPosition.salary || ''
        }, null, 8, ["responsabilities", "requirements", "additional", "salary"]))
      : _createCommentVNode("", true),
    (_ctx.selectedPosition && _ctx.selectedPosition.colleagues)
      ? (_openBlock(), _createBlock(_component_Colleague, {
          key: 1,
          colleagues: _ctx.selectedPosition.colleagues
        }, null, 8, ["colleagues"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PositionPerks, {
      perks: _ctx.PositionData.perks
    }, null, 8, ["perks"]),
    _createVNode(_component_PositionForm)
  ]))
}