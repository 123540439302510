
import { defineComponent } from 'vue';

// Data
import constants from '@/components/global/constants';

export default defineComponent({
  name: 'ContactForm',
  data() {
    return {
      email: constants.cvEmail,
    };
  },
  computed: {
    position() {
      const pos = this.$route.params.position as string;
      return pos.replace(/-/g, ' ');
    },
  },
});
