
import { defineComponent, PropType } from 'vue';

interface IQuoteData {
  side: string;
  image: string;
  text: string;
  author: string;
}

export default defineComponent({
  name: 'Quote',
  props: {
    quoteData: {
      required: true,
      type: Object as PropType<IQuoteData>,
    },
  },
});
