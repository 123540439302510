import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhiteLabel = _resolveComponent("WhiteLabel")!
  const _component_OtherSolutions = _resolveComponent("OtherSolutions")!
  const _component_FAIcon = _resolveComponent("FAIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContactForm = _resolveComponent("ContactForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_WhiteLabel),
    _createVNode(_component_OtherSolutions),
    _createVNode(_component_router_link, {
      class: "link",
      to: {
        name: 'Products',
      }
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Discover Products "),
        _createVNode(_component_FAIcon, {
          icon: "arrow-right",
          class: "ico"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ContactForm)
  ]))
}