import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e0aa9cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "slideshow",
  ref: "slider"
}
const _hoisted_2 = {
  key: 0,
  class: "dots"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.showDots)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array(_ctx.count), (_, dotIndex) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `dot-${dotIndex}`,
              class: _normalizeClass(["dot", {active: _ctx.active === dotIndex}])
            }, null, 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}