
import { defineComponent, PropType } from 'vue';

// Slider component
import SliderTop from '@/components/global/SliderTop.vue';

// Interface
interface IPositionPerks {
  [coupleIndex: number]: string[]
}

export default defineComponent({
  name: 'ContactsView',
  props: {
    perks: {
      required: true,
      type: Object as PropType<IPositionPerks>,
    },
  },
  components: {
    SliderTop,
  },
});
