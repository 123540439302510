
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ContactsView',
  props: {
    responsabilities: {
      required: true,
      type: Array as PropType<string[]>,
    },
    requirements: {
      required: true,
      type: Array as PropType<string[]>,
    },
    additional: {
      required: true,
      type: Array as PropType<string[]>,
    },
    salary: {
      required: true,
      type: String,
    },
  },
});
