
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import c from '@/components/global/constants';

export default defineComponent({
  setup() {
    const store = useStore();

    return {
      positions: computed(() => store.state.positions),
    };
  },
  data() {
    return {
      ...c,
    };
  },
});
