
import { defineComponent, PropType } from 'vue';

import { addSideSwipeHandler, removeSideSwipeHandler } from '@/components/global/touchHandler';

interface Image {
  src: string;
}

export default defineComponent({
  name: 'GalleryBox',
  props: {
    startWithIndex: {
      required: false,
      type: Number,
      default: null,
    },
    images: {
      required: true,
      type: Object as PropType<Image[]>,
    },
  },
  data() {
    return {
      gallerySize: 0,
      prevIndex: 0,
      imageIndex: 0,
      nextIndex: 0,
    };
  },
  computed: {
    imageSrc() {
      const { images, imageIndex } = this;

      if (!images) return '';

      const image = images[imageIndex] as Image;
      return image.src || '';
    },
  },
  watch: {
    startWithIndex: {
      immediate: true,
      handler(newValue) {
        document.body.classList.remove('overflow');

        if (newValue === null) return;
        document.body.classList.add('overflow');

        this.imageIndex = newValue;
        this.selectImage();
        this.$nextTick(this.setupNavigationHandlers);
      },
    },
    images: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        this.gallerySize = Object.keys(this.images).length;
      },
    },
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.keyDownEvent);

    const gallery = this.$refs.gallery as HTMLElement;
    if (!gallery) return;

    removeSideSwipeHandler(gallery, this.selectImage);
  },
  methods: {
    selectImage(side?: string): void {
      if (side === 'right') {
        this.imageIndex = this.nextIndex;
      }
      if (side === 'left') {
        this.imageIndex = this.prevIndex;
      }

      const { gallerySize, imageIndex } = this;
      this.prevIndex = imageIndex === 0 ? gallerySize - 1 : imageIndex - 1;
      this.nextIndex = imageIndex === (gallerySize - 1) ? 0 : imageIndex + 1;
    },
    setupNavigationHandlers() {
      document.addEventListener('keydown', this.keyDownEvent);

      const gallery = this.$refs.gallery as HTMLElement;
      if (!gallery) return;
      addSideSwipeHandler(gallery, this.selectImage);
    },
    keyDownEvent(event: Event) {
      const ev = (event || window.event) as KeyboardEvent;

      if (ev.key === 'Escape' || ev.key === 'Esc') {
        this.close();
      }
      if (ev.key === 'ArrowRight') {
        this.selectImage('right');
      }
      if (ev.key === 'ArrowLeft') {
        this.selectImage('left');
      }
    },
    close() {
      this.$emit('clear');
    },
  },
});
