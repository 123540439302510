
import { defineComponent } from 'vue';

// Data
import CareerData from '@/components/view/career/CareerData';

// Components
import Perks from '@/components/view/career/Perks.vue';
import HRs from '@/components/view/career/HRs.vue';
import TechStack from '@/components/view/career/TechStack.vue';
import Positions from '@/components/view/homepage/Positions.vue';

export default defineComponent({
  name: 'CareerView',
  components: {
    Perks,
    HRs,
    TechStack,
    Positions,
  },
  data() {
    return {
      CareerData,
    };
  },
});
