export default {
  businessEmail: 'sales@adv.bet',
  careerEmail: 'cv@adv.bet',
  whatsapp: 'https://wa.me/27711458911',
  formsEmail: 'sales@adv.bet',
  cvEmail: 'cv@adv.bet',
  locations: [
    {
      office: 'Vilnius',
      address: 'Saltoniškių g. 2, LT-08126',
      location: 'Vilnius, Lithuania',
    },
    {
      office: 'Kaunas',
      address: 'MAGNUM | K. Mindaugo pr. 38, LT-44307',
      location: 'Kaunas, Lithuania',
    },
  ],
};
