
import { defineComponent } from 'vue';

// Data
import SolutionsData from '@/components/view/solutions/SolutionsData';

export default defineComponent({
  name: 'DeliveringResults',
  data() {
    return {
      expanded: '',
      retailPoints: SolutionsData.retailPoints,
      onlinePoints: SolutionsData.onlinePoints,
    };
  },
  methods: {
    toggleExpanded(title: string): void {
      this.expanded = this.expanded === title ? '' : title;
    },
  },
});
