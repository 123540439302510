import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SlideShow = _resolveComponent("SlideShow")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Promo = _resolveComponent("Promo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_SlideShow),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer),
    (_ctx.showPromo)
      ? (_openBlock(), _createBlock(_component_Promo, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}