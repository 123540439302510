
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

import { addSideSwipeHandler } from '@/components/global/touchHandler';

// Data
import { SlideList, ISlide, AllowedSlides } from '@/components/global/SlideShow/SlidesData';

// Interface
interface IPosition {
  title: string;
  short: string;
  responsabilities: string[];
  requirements: string[];
  additional?: string[];
  colleague: {
    image: string,
    name: string,
    position: string,
  }
}

export default defineComponent({
  name: 'SlideShow',
  setup() {
    const store = useStore();

    return {
      positions: computed(() => store.state.positions),
    };
  },
  data() {
    return {
      slides: SlideList,
      interval: 0,
      slideChangeEverySec: 10,
      secondsLeft: 10,
      slidePositions: {
        active: 0,
        prev: 0,
        next: 0,
      },
    };
  },
  computed: {
    pageName(): AllowedSlides {
      const page = this.$route.name?.toString().toLowerCase() || '';

      switch (page) {
        case 'home':
        case 'about':
        case 'career':
        case 'solutions':
        case 'products':
        case 'position':
          return page;
        case 'product':
          return 'products';
        default:
          return '';
      }
    },
    slide(): ISlide[] {
      const { slides, pageName } = this;
      const currentSlide = slides[this.pageName];

      if (pageName === 'position') {
        const { selectedPosition } = this;

        currentSlide[0].title.splice(0, 10);

        if (selectedPosition) {
          currentSlide[0].title.push({
            text: selectedPosition?.title || '',
          });
          currentSlide[0].title.push({
            text: selectedPosition?.short || '',
          });
        }
      }

      return currentSlide;
    },
    selectedPosition(): IPosition | undefined {
      const { position } = this.$route.params;

      return this.positions.find((p) => {
        const { title, short } = p;
        const generatedPath = `${title} ${short}`
          .replace(/,/g, '') // Replace all ','
          .replace(/ \/ /g, ' ') // Replace all ' / '
          .replace(/\//g, '') // Replace all '/'
          .replace(/ /g, '-') // Replace all ' '
          .toLowerCase();

        return generatedPath === position;
      });
    },
  },
  watch: {
    pageName(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.slidePositions.active = 0;
        this.updateSlideCounts();
        this.resetTimeLeft();
      }
    },
  },
  mounted() {
    this.startTimer();
    this.prepareSlide();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    prepareSlide(): void {
      const slideEl = this.$refs.slide as HTMLElement;
      addSideSwipeHandler(slideEl, (side: string) => {
        if (side === 'left') {
          this.prevSlide();
        } else {
          this.nextSlide();
        }
      });
      this.updateSlideCounts();
    },
    updateSlideCounts(): void {
      const { slide, slidePositions } = this;
      const slideID = slidePositions.active;

      if (!slide || slide.length < 2) return;
      this.slidePositions.prev = slideID === 0 ? slide.length - 1 : slideID - 1;
      this.slidePositions.next = slideID === (slide.length - 1) ? 0 : slideID + 1;
    },
    startTimer() {
      this.slidePositions.active = 0;
      // Every second
      this.interval = setInterval(this.tick, 1000);
    },
    tick() {
      const { secondsLeft } = this;

      if (!this.slide || this.slide.length === 1) {
        return;
      }

      if (secondsLeft <= 0) {
        this.nextSlide();
        return;
      }

      this.secondsLeft = secondsLeft <= 0 ? this.slideChangeEverySec : secondsLeft - 1;
    },
    nextSlide() {
      this.slidePositions.active = this.slidePositions.active >= (this.slide.length - 1)
        ? 0 : this.slidePositions.active + 1;
      this.resetTimeLeft();
      this.updateSlideCounts();
    },
    // Leave this for possible future arrow update
    prevSlide() {
      this.slidePositions.active = this.slidePositions.active === 0
        ? (this.slide.length - 1) : this.slidePositions.active - 1;
      this.resetTimeLeft();
      this.updateSlideCounts();
    },
    resetTimeLeft() {
      this.secondsLeft = this.slideChangeEverySec;
    },
    selectSlide(slideID: number) {
      this.slidePositions.active = slideID;
      this.resetTimeLeft();
    },
    linkMe(link: string | undefined) {
      if (!link) return;
      this.$router.push(link);
    },
  },
});
