
import { defineComponent } from 'vue';

import { addSideSwipeHandler, removeSideSwipeHandler } from '@/components/global/touchHandler';

export default defineComponent({
  props: {
    speed: {
      required: false,
      type: Number,
      default: 3,
    },
    showDots: {
      required: false,
      type: Boolean,
      default: true,
    },
    itemsPerSlide: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      count: 0,
      active: 0,
      next: 0,
      prev: 0,
      timer: 0,
      secLeft: 0,
    };
  },
  mounted() {
    const slider = this.$refs.slider as HTMLElement;
    this.count = (slider.children.length - 1) / this.itemsPerSlide;
    this.secLeft = this.speed;

    this.setValues();
    this.timer = setInterval(this.tick, 1000);

    addSideSwipeHandler(slider, this.swipe);
  },
  beforeUnmount() {
    const slider = this.$refs.slider as HTMLElement;
    removeSideSwipeHandler(slider, this.swipe);

    clearInterval(this.timer);
  },
  methods: {
    swipe(side: string): void {
      this.setValues(side);
      this.secLeft = this.speed;
    },
    genRange(position: number): { from: number, to: number } {
      const { itemsPerSlide } = this;

      return {
        from: itemsPerSlide * position,
        to: itemsPerSlide * position + (itemsPerSlide - 1),
      };
    },
    updateClasses() {
      const slider = this.$refs.slider as HTMLElement;
      const count = slider.children.length;
      const {
        active, prev, next, genRange,
      } = this;

      // This is so that there could be more than one element that scrolls
      const ranges = {
        active: genRange(active),
        next: genRange(next),
        prev: genRange(prev),
      };

      for (let index = 0; index < count; index += 1) {
        const element = slider.children[index];
        element.classList.remove('active', 'prev', 'next');

        if (index >= ranges.active.from && ranges.active.to >= index) {
          element.classList.add('active');
        }
        if (index >= ranges.prev.from && ranges.prev.to >= index) {
          element.classList.add('prev');
        }
        if (index >= ranges.next.from && ranges.next.to >= index) {
          element.classList.add('next');
        }
      }
    },
    setValues(side?: string) {
      if (side === 'left') {
        this.active = this.prev;
      }
      if (side === 'right') {
        this.active = this.next;
      }
      const { active, count } = this;
      this.prev = active === 0 ? count - 1 : active - 1;
      this.next = active === (count - 1) ? 0 : active + 1;

      this.updateClasses();
    },
    tick() {
      const { secLeft } = this;
      if (secLeft <= 0) {
        this.setValues('right');
      }
      this.secLeft = secLeft <= 0 ? this.speed : secLeft - 1;
    },
  },
});
