
import { defineComponent } from 'vue';
import Menu from './Header/Menu.vue';

export default defineComponent({
  components: { Menu },
  data() {
    return {
      showMobileMenu: false,
    };
  },
});
