
import { defineComponent } from 'vue';

// Data
import AboutData from '@/components/view/about/AboutData';

// Components
import Quote from '@/components/view/about/Quote.vue';
import WhatMakesUs from '@/components/view/about/WhatMakesUs.vue';
import TripleBlock from '@/components/global/TripleBlock.vue';
import OurWay from '@/components/view/about/OurWay.vue';
import StartToday from '@/components/view/about/StartToday.vue';

export default defineComponent({
  name: 'AboutView',
  components: {
    WhatMakesUs,
    Quote,
    TripleBlock,
    OurWay,
    StartToday,
  },
  data() {
    return {
      AboutData,
    };
  },
});
