import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1490e15b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-fill menu" }
const _hoisted_2 = {
  key: 0,
  class: "count"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: link.name,
        class: "link",
        to: link.path,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigate')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(link.name) + " ", 1),
          (link.positions)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.positions.length), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalLinks, (link) => {
      return (_openBlock(), _createElementBlock("a", {
        key: link.name,
        href: link.path,
        target: "_blank",
        class: "link"
      }, _toDisplayString(link.name), 9, _hoisted_3))
    }), 128))
  ]))
}