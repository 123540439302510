import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46330aea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "triple" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderTop = _resolveComponent("SliderTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SliderTop, {
      class: _normalizeClass(["list", [_ctx.group]])
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocksData, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "list-item animate",
            key: `list-item-${item.title}`
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["icon mask", item.icon])
            }, null, 2),
            _createElementVNode("h3", null, _toDisplayString(item.title), 1),
            _createElementVNode("p", null, _toDisplayString(item.description), 1)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}