import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78544cac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "our-work" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatMakesUs = _resolveComponent("WhatMakesUs")!
  const _component_Quote = _resolveComponent("Quote")!
  const _component_TripleBlock = _resolveComponent("TripleBlock")!
  const _component_OurWay = _resolveComponent("OurWay")!
  const _component_StartToday = _resolveComponent("StartToday")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_WhatMakesUs),
    _createVNode(_component_Quote, {
      quoteData: _ctx.AboutData.quotes.simas
    }, null, 8, ["quoteData"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TripleBlock, {
          group: 'our-work',
          blocksData: _ctx.AboutData.work
        }, null, 8, ["blocksData"])
      ])
    ]),
    _createVNode(_component_Quote, {
      quoteData: _ctx.AboutData.quotes.zilvinas
    }, null, 8, ["quoteData"]),
    _createVNode(_component_OurWay),
    _createVNode(_component_Quote, {
      quoteData: _ctx.AboutData.quotes.toma
    }, null, 8, ["quoteData"]),
    _createVNode(_component_StartToday)
  ]))
}