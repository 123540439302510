import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84803d72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["quote", _ctx.quoteData.side])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: require(`@/assets/img/people/${_ctx.quoteData.image}.png`),
          alt: _ctx.quoteData.author
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.quoteData.text), 1),
        _createElementVNode("span", _hoisted_6, " - " + _toDisplayString(_ctx.quoteData.author), 1)
      ])
    ])
  ], 2))
}