import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HearFromYou = _resolveComponent("HearFromYou")!
  const _component_VisitUs = _resolveComponent("VisitUs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HearFromYou),
    _createVNode(_component_VisitUs)
  ]))
}