
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PartnersView',
  data() {
    return {
      partners: [
        'tvmanager.svg',
        'betgames.svg',
        'betradar.svg',
        'agt.svg',
        'evolution.svg',
        'pragmatic.svg',
        'sis.svg',
        'arc.svg',
        'kiron.svg',
        'globalbet.svg',
        'betdata.svg',
        'habanero.svg',
        'amatic.svg',
      ],
    };
  },
});
