
import { defineComponent, PropType } from 'vue';

// Interface
interface IHR {
  image: string;
  name: string;
  email: string;
  position: string;
  linkedin: string;
}

export default defineComponent({
  name: 'CareerHRs',
  props: {
    hrList: {
      required: true,
      type: Array as PropType<IHR[]>,
    },
  },
});
