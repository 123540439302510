export default {
  retailPoints: [
    {
      title: 'Administrators Application',
      description: `Designed to give full access and exercise decision making. 
        Using your expertise and local market knowledge you are free to carry out 
        all administrative tasks with ease and simplicity.`,
    },
    {
      title: 'Cashier\'s Application',
      description: `Incredibly intuitive and straightforward to use, enabling cashiers 
        to rapidly accept bets, make payouts, facilitate deposits and withdrawals as 
        well as print betting slips and shift summaries.`,
    },
    {
      title: 'Verify Client Information',
      description: `Attract and register new players as well as verify client's 
        information according to your local laws and regulations.`,
    },
    {
      title: 'Bonus System',
      description: `Retain and engage your current players using customisable bonus 
        campaigns to reward them as well as other data-driven campaigns using CRM and 
        segmental analysis tools.`,
    },
    {
      title: 'Anti-Fraud Measures',
      description: `Up-to-date anti-fraud features help to eliminate a range of 
        fraudulent behaviours common to the industry such as cashier frauds, player 
        frauds, administrator frauds and systemic frauds.`,
    },
    {
      title: 'Rapid Bet Acceptance',
      description: `High-performance platform allows the betting shop to accept more 
        than 500 transactions per second, making it high-end by the latest industry 
        standards.`,
    },
    {
      title: 'Differential Odds & Products',
      description: `Betting shops can offer by location improved odds or specific 
        games in regions where competition is fierce. Tailored product offering and 
        adjustment is seamless.`,
    },
    {
      title: 'Jackpot Features',
      description: `Designed to maximise customer appeal for all betting products and 
        allow for the targeting of each promotion on a modular and fully configurable basis.`,
    },
    {
      title: 'Modular Reports',
      description: `Generate complex modular reports of data necessary for decision 
        making. Generate and segment reports by organization, shops, cashier or shifts.`,
    },
    {
      title: 'Plug & Play Set Up',
      description: `Ready to be used out-of-the box a 100% web-based application meaning 
        that there is no installation process and you have complete freedom over your 
        hardware choices.`,
    },
    {
      title: 'Book-A-Bet Feature',
      description: `Allows the players to make full betting selections on their own 
        devices and significantly reduce the time when placing bets with the tellers.`,
    },
  ],
  onlinePoints: [
    {
      title: 'Multi-Device Support',
      description: `Responsive design ensures that players can place bets and engage 
        with the content conveniently using desktop, tablet or mobile phone on the go.`,
    },
    {
      title: 'Content Management System',
      description: `A convenient interface to manage all content and layout that is 
        being displayed on the website, including games, promotions and banners.`,
    },
    {
      title: 'Multiple Language Support',
      description: `Choose out of 7 languages that the system currently fully supports 
        or request additional language to be added for your operation.`,
    },
    {
      title: 'Payment Gateway Integrations',
      description: `Multiple payment methods and gateways fully integrated and ready 
        to run transactions in multiple markets.`,
    },
    {
      title: 'Resilient Business Operation',
      description: `The online operation requires less personnel and is immune to 
        lockdowns, quarantines or other uncertainties.`,
    },
    {
      title: 'Engaging Content',
      description: `Multiple methods to engage players or encourage them to bet more 
        and play more often, such as loyalty programs, bonuses and holiday promotions.`,
    },
    {
      title: 'Exclusive Games',
      description: `Online platform offers clients exclusive products such as hundreds
        of slot games and multiple live table games.`,
    },
    {
      title: 'Flexible Bet Options',
      description: `Provide clients with unparalleled betting options and variations 
        exclusive to online platform, live cash-out feature and bet tracking option.`,
    },
  ],
};
