
import { defineComponent, PropType } from 'vue';

// Slider component
import SliderTop from '@/components/global/SliderTop.vue';

interface IBlockData {
  icon: string;
  title: string;
  description: string;
}

export default defineComponent({
  name: 'TripleBlock',
  props: {
    blocksData: {
      required: true,
      type: Array as PropType<IBlockData[]>,
    },
    group: {
      required: false,
      type: String as PropType<'ourWork' | 'ourWay' | 'makesUs' >,
    },
  },
  components: {
    SliderTop,
  },
});
