
import { defineComponent, PropType } from 'vue';

// Slider component
import SliderTop from '@/components/global/SliderTop.vue';

// Interface
interface IPerks {
  icon: string;
  title: string;
}

export default defineComponent({
  name: 'CareerPerks',
  props: {
    perks: {
      required: true,
      type: Array as PropType<IPerks[]>,
    },
  },
  components: {
    SliderTop,
  },
});
