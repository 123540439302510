export default {
  perks: [
    [
      `You will work alongside some of the best experts in their fields - 
        seriously “genius” level brainiacs. Peer-to-peer review will help 
        you improve your work and learn the best practices and solutions.`,
      `An exciting growing start-up atmosphere, where the structure is flat 
        and decision implementation is fast. Pitch ideas or suggestions and 
        outgrow your position with the company's success.`,
    ],
    [
      `We offer flexibility when it comes to working hours and places - need 
        to take care of kids or pop out for an hour or two - no worries. Work 
        from home or join us in the cozy office in Vilnius or Kaunas - your choice!`,
      `Knowing is the end of learning, that's why we push our teams to grow professionally 
        - self-development, training, courses, conferences - whatever you need to go to 
        the next level!`,
    ],
    [
      `Awesome working environment and fun colleagues - you'll be surprised! Regular 
        team buildings, sport events, road trips and summer parties, bring your spouses 
        and kids - everyone is invited!`,
      `We encourage our colleagues to embrace a healthy lifestyle, anyone running or 
        cycling can track and share their progress in the STRAVA app - best achievers get 
        full membership as a prize!`,
    ],
    [
      `We will greet you with a starter pack with our cool merch and an always fully 
        stocked kitchen with healthy snacks and beverages to fuel your productivity 
        and brain power!`,
      `Honest pay for honest work. If you can WOW us, we are always 
        open to discuss a different salary based on your skills and competences.`,
    ],
  ],
};
