
import { defineComponent } from 'vue';

// Data
import constants from '@/components/global/constants';

export default defineComponent({
  name: 'ContactForm',
  data() {
    return {
      email: constants.formsEmail,
    };
  },
});
