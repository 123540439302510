
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  setup() {
    const store = useStore();

    return {
      positions: computed(() => store.state.positions),
    };
  },
  data() {
    return {
      links: [
        {
          name: 'Home',
          path: '/',
        },
        {
          name: 'Solutions',
          path: '/solutions',
        },
        {
          name: 'Products',
          path: '/products',
        },
        {
          name: 'About',
          path: '/about',
        },
        {
          name: 'Career',
          path: '/career',
          positions: true,
        },
        {
          name: 'Contacts',
          path: '/contacts',
        },
      ],
      externalLinks: {},
    };
  },
});
