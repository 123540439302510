
import { defineComponent } from 'vue';

// Components
import GalleryBox from '@/components/global/GalleryBox.vue';

// Data
import constants from '@/components/global/constants';

export default defineComponent({
  name: 'VisitUs',
  components: {
    GalleryBox,
  },
  data() {
    return {
      locations: constants.locations,
      imageIndex: null,
      images: [
        {
          src: 'contacts/1.jpeg',
          thumb: 'contacts/1_thumb.jpg',
        },
        {
          src: 'contacts/2.jpeg',
          thumb: 'contacts/2_thumb.jpg',
        },
        {
          src: 'contacts/3.jpeg',
          thumb: 'contacts/3_thumb.jpg',
        },
        {
          src: 'contacts/4.jpeg',
          thumb: 'contacts/4_thumb.jpg',
        },
        {
          src: 'contacts/5.jpeg',
          thumb: 'contacts/5_thumb.jpg',
        },
        {
          src: 'contacts/6.jpeg',
          thumb: 'contacts/6_thumb.jpg',
        },
      ],
    };
  },
});
