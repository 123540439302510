
import { defineComponent } from 'vue';

// Data
import ProductList from '@/components/view/products/ProductList';

// Components
import DesiredProducts from '@/components/view/homepage/DesiredProducts.vue';
import GalleryBox from '@/components/global/GalleryBox.vue';

// Interfaces
interface IGameData {
  name: string;
  live?: boolean;
  rng?: boolean;
  description: string[];
  features: string[];
  images?: string[];
}

interface IFormedImage {
  src: string;
  thumb: string;
}

export default defineComponent({
  name: 'ProductSelect',
  components: {
    DesiredProducts,
    GalleryBox,
  },
  data() {
    return {
      ProductList,
      imageIndex: null,
      showingImage: 0,
    };
  },
  computed: {
    selectedGame(): string | string[] {
      return this.$route.params.game || ProductList[0].url;
    },
    gameData(): IGameData {
      return ProductList.find((g) => g.url === this.selectedGame) || ProductList[0];
    },
    gameImages(): IFormedImage[] {
      const { gameData } = this;
      return gameData.images
        ?.map((img: string) => (
          {
            src: `products/games/${img}`,
            thumb: `products/games/${img}`,
          }
        )) || [];
    },
  },
  watch: {
    selectedGame(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showingImage = 0;
      }
    },
  },
  methods: {
    slide(side: 'left' | 'right'): void {
      const currIndex = ProductList.findIndex((g) => g.url === this.selectedGame);
      let nextIndex = 0;

      if (side === 'left') {
        nextIndex = (currIndex === undefined || currIndex === 0 ? ProductList.length : currIndex) - 1;
      }
      if (side === 'right') {
        nextIndex = currIndex === undefined || currIndex === (ProductList.length - 1) ? 0 : currIndex + 1;
      }

      this.$router.push({
        name: 'Product',
        params: {
          game: ProductList[nextIndex].url,
        },
      });
    },
  },
});
