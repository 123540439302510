
import { defineComponent } from 'vue';

// Data

export default defineComponent({
  name: 'ContactsView',
  components: {
  },
  data() {
    return {
    };
  },
});
