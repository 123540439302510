import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/components/view/Homepage.vue';
import Solutions from '@/components/view/Solutions.vue';
import Products from '@/components/view/Products.vue';
import About from '@/components/view/About.vue';
import Contacts from '@/components/view/Contacts.vue';
import CareerHolder from '@/components/view/CareerHold.vue';
import Career from '@/components/view/Career.vue';
import Position from '@/components/view/Position.vue';
import ExhibitionsView from '@/components/view/ExhibitionsView.vue';
import PartnersView from '@/components/view/PartnersView.vue';
import CompanyDockView from '@/components/view/CompanyDockView.vue';
import Cookies from '@/components/view/Cookies.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies,
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: Solutions,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    children: [
      {
        path: ':game',
        name: 'Product',
        component: Products,
        props: (route) => ({
          game: route.params.game,
        }),
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/career',
    name: 'CareerHolder',
    component: CareerHolder,
    children: [
      {
        path: '',
        name: 'Career',
        component: Career,
      },
      {
        path: ':position',
        name: 'Position',
        component: Position,
        props: (route) => ({
          position: route.params.position,
        }),
      },
    ],
  },
  {
    path: '/exhibitions',
    name: 'Exhibitions',
    component: ExhibitionsView,
  },
  {
    path: '/partners',
    name: 'Partners',
    component: PartnersView,
  },
  {
    path: '/booklet',
    name: 'CompanyDock',
    component: CompanyDockView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Browser back/forward behavior
    if (savedPosition) {
      return savedPosition;
    }

    // Hash behavior
    if (to.hash) {
      if (to.name === from.name) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }
      return new Promise((resolve) => {
        window.scroll(0, 0);
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
          });
        }, 200);
      });
    }

    if (to.name === 'Product' && from.name === 'Products') {
      return {};
    }

    if (to.name === from.name) {
      return {};
    }

    // Product data behavior
    return {
      top: 0,
    };
  },
});

export default router;
