export default {
  whatMakeUs: [
    {
      icon: 'industry_insights',
      title: 'Industry Insights',
      description: `The co-founders have been operational bookmakers 
        themselves for decades before launching Aardvark Technologies 
        to solve industry's problems.`,
    },
    {
      icon: 'immense_experience',
      title: 'Immense Experience',
      description: `Industry knowledge and collective experience of 
        over 100 years is the driving force behind every system development 
        and decision making.`,
    },
    {
      icon: 'competitive_dna',
      title: 'Competitive DNA',
      description: `Competitiveness is one of the essential elements of 
        our company's DNA - the fiercer the competition, the more 
        motivation we have to grow and improve even faster.`,
    },
  ],
  work: [
    {
      icon: 'high_performance',
      title: 'High-Performance',
      description: `We created a platform with a high-performance 
        transaction engine with extremely high bet acceptance rates of 
        more than 500 transactions per second.`,
    },
    {
      icon: 'real_time_support',
      title: 'Real-Time Support',
      description: `There is a professional operations team ready to 
        solve any partner bookmaker's issues through real-time 
        communication via Skype, WhatsApp, Zendesk or other channels.`,
    },
    {
      icon: 'flexible_agile',
      title: 'Flexible & Agile',
      description: `We provide betting and gambling software for market 
        professionals. We are fast to adapt and deliver on tailor-made 
        solutions for various size operations.`,
    },
  ],
  ourWay: [
    {
      icon: 'our_team',
      title: 'Our Team',
      description: `Aardvark Technologies employs top-level frontend and 
        backend developers, DevOps and QA engineers and has a 24-hour 
        customer service department in the cozy offices of Kaunas and 
        Vilnius.`,
    },
    {
      icon: 'perks',
      title: 'Perks',
      description: `Our flexible approach to working hours and the 
        constant encouragement of employees to develop professionally 
        through training, courses and international conferences are what 
        make us an attractive employer.`,
    },
    {
      icon: 'ideas',
      title: 'Ideas',
      description: `We are home to some of the most brilliant minds in 
        the industry, seriously “genius” level. It allowed us to rapidly 
        become a dominant force as a software company internationally!`,
    },
  ],
  quotes: {
    simas: {
      side: 'left',
      image: 'simas',
      text: `"The idea to create a platform - a software for real-time 
        administration of various betting management systems - came about 
        from seeing the industry from within."`,
      author: 'Simas, CEO',
    },
    zilvinas: {
      side: 'right',
      image: 'zilva',
      text: `"A dedicated team of professionals operate 24/7 and we are 
        ready to solve any issues of our partners 365 days a year."`,
      author: 'Žilvinas, COO',
    },
    toma: {
      side: 'left',
      image: 'toma',
      text: `"We believe that work-life balance is key to the success and
        happiness. We created YOU DECIDE company culture where the balance is set by YOU!"`,
      author: 'Toma, Human Resources Manager',
    },
  },
};
