
import { defineComponent } from 'vue';

// Components
import MeetUs from '@/components/view/exhibitions/MeetUs.vue';
import Gallery from '@/components/view/exhibitions/Gallery.vue';

export default defineComponent({
  components: { MeetUs, Gallery },
  name: 'ExhibitionsView',
});
