
import { defineComponent, PropType } from 'vue';

// Interface
import { IColleague } from '@/store/IState';

export default defineComponent({
  name: 'Colleague',
  props: {
    colleagues: {
      required: true,
      type: Array as PropType<IColleague[]>,
    },
  },
});
