
import { defineComponent } from 'vue';

// Data
import constants from '@/components/global/constants';

// Components
import ContactForm from '@/components/global/ContactForm.vue';

export default defineComponent({
  name: 'HearFromYou',
  components: { ContactForm },
  data() {
    return {
      business: constants.businessEmail,
      career: constants.careerEmail,
    };
  },
});
