
import { defineComponent } from 'vue';
import ContactForm from '@/components/global/ContactBlock.vue';
import OtherSolutions from './solutions/OtherSolutions.vue';
import WhiteLabel from './solutions/WhiteLabel.vue';

export default defineComponent({
  components: { WhiteLabel, OtherSolutions, ContactForm },
  name: 'SolutionsView',
});
