
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DeliveringResults',
  data() {
    return {
      activeIndex: 1,
      timeout: 0,
      tickEveryMs: 3000,
    };
  },
  mounted() {
    this.timeout = setTimeout(this.tick, this.tickEveryMs);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: {
    tick() {
      this.activeIndex = this.activeIndex >= 4 ? 1 : this.activeIndex + 1;
      this.timeout = setTimeout(this.tick, this.tickEveryMs);
    },
  },
});
