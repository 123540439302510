
import { defineComponent } from 'vue';

// Data
import constants from '@/components/global/constants';

export default defineComponent({
  name: 'WhatsApp',
  data() {
    return {
      whatsApp: constants.whatsapp,
    };
  },
});
