
import { defineComponent, PropType } from 'vue';
import ProductList from '@/components/view/products/ProductList';

export default defineComponent({
  name: 'DesiredProducts',
  props: {
    placement: {
      type: String as PropType<'home' | 'products'>,
      required: false,
      default: 'homepage',
    },
  },
  data() {
    return {
      games: ProductList,
    };
  },
  computed: {
    activeGame(): string {
      if (!this.$route.params.game && this.placement === 'products') {
        return ProductList[0].url;
      }
      return this.$route.params.game as string;
    },
  },
});
