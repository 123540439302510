
import { defineComponent } from 'vue';

// Data
import AboutData from '@/components/view/about/AboutData';
import HearFromYou from '@/components/view/contacts/HearFromYou.vue';
import VisitUs from '@/components/view/contacts/VisitUs.vue';

export default defineComponent({
  name: 'ContactsView',
  components: {
    HearFromYou,
    VisitUs,
  },
  data() {
    return {
      AboutData,
    };
  },
});
