export default [
  {
    name: 'Prematch & In Play',
    url: 'prematch-and-in-play',
    icon: 'sports',
    live: true,
    rng: false,
    description: [
      `Aardvark Technologies is a Sport Radar certified partner qualified to provide 
      professional Pre-Match Betting and Live Betting Services using their UOF 
      (Unified Odds Feed) supplemented by the MTS (Managed Trading Service) to protect 
      from unexpected risks and liabilities.`,
      `Aardvark have worked closely with our bookmaker partners to create our world 
      beating, comprehensive and easy to understand Live Sports betting screen system 
      that enables enhanced live action.`,
    ],
    features: [
      'Enjoy 24/7 betting',
      'Resulting Live with managed risk of incorrect/late resulting',
      'Easy to learn for new players',
      'Large betting market with various sports',
      'Straightforward betting market',
    ],
    images: ['sports_online.png', 'sports_retail.png'],
  },
  {
    name: 'Greyhounds',
    url: 'greyhounds',
    icon: 'greyhounds',
    live: true,
    rng: true,
    description: [
      `Aardvark offers a world class recorded dogs' game that enables players to place 
      bets at variable durations as set by each bookmaker on a variety of compelling 
      bet types which have highest GGR results when compared to other pre-recorded dog 
      products in the market.`,
      `This has been achieved because there are multiple odds spread templates (for a 
      given overall book overround) - which can be deployed for different markets. Where 
      customers like to bet on safer outcomes (e.g. favourites to win) as is the case in 
      developed European markets, the odds on the outsiders are consistent with real dog 
      races. For markets where players hunt 100/1 type outcomes (for example African 
      markets), these too are catered for.`,
    ],
    features: [
      '8 unique betting options',
      'Place bets up to 10 races ahead',
      'Highest odds in the market',
      'Customisable game screen messages',
      'Low usage of broadband',
      'Progressive jackpot system',
      'Mobile & online integration',
    ],
    images: ['greyhounds_online.png', 'greyhounds_retail.png'],
  },
  {
    name: 'Horse Racing',
    url: 'horses',
    icon: 'horses',
    live: true,
    rng: true,
    description: [
      `Racehorses aren't just born; they are bred. Same with Aardvark platform, we have 
      combined extensive bookmaker licensee expertise with our partner rights holders 
      to offer a product that enables seamless wagering options on racing from around 
      the world.`,
      `Aardvark is fully integrated with both ARC & SIS and hence is able to provide partner 
      bookmakers with real time coverage of all racing from tracks in the UK, South Africa, 
      Australia, Dubai, Ireland, France, Germany alongside subsidiary racing from other 
      jurisdictions.`,
    ],
    features: [
      '4 popular betting options',
      'Each-Way Bet Option',
      'Totalisator Bet Option',
      'High Definition Stream',
      'Low usage of broadband',
      'Progressive jackpot system',
      'Mobile & online integration',
    ],
    images: ['horses_online.png', 'horses_retail.png'],
  },
  {
    name: 'Lotteries',
    url: 'lotteries',
    icon: 'lotto',
    live: true,
    rng: false,
    description: [
      `Aardvark's Global Numbers Game offers a real-time lottery result based fixed odds 
      betting product for all bookmakers interested to take wagers on such draws.`,
      `Aardvark offers more than 200 lotteries from around the world, supported on a 24/7 
      resulting basis with betting markets able to be enabled on a draw by draw basis 
      alongside underlying odds. Customizable limits per selection, per event, per product 
      and win per bet leg are able to be set.`,
    ],
    features: [
      `Extensive array of bet types offered on Pick 3, Pick 4, 5 ball, 6 ball and 7 ball 
      draws (with & without bonus / power balls)`,
      'Product based on real lottery draw events around the Globe',
      'Customisable odds for each bookmaker',
      'Countdown for upcoming draws',
      'Unique betting markets & bet types for each draw',
      'Flexible limits configuration',
      'Favourite lottery draws able to be allocated',
    ],
    images: ['lotto_online.png', 'lotto_retail.png'],
  },
  {
    name: 'Betgames',
    url: 'betgames',
    icon: 'betgames',
    live: true,
    rng: true,
    description: [
      `A full selection of world class Bet Games products are offered via the Aardvark 
      platform that are both popular with customers and easy to manage from a cashier's 
      perspective.`,
      `The customer display screens supplemented with ability to overlay multi leg 
      accumulator bonuses, variable odds and progressive jackpot make this implementation 
      of these games the best in the world.`,
    ],
    features: [
      'The fullest array in the world of bet types offered on each draw',
      'Interactive user screen design',
      'Progressive jackpot system',
      'Live broadcasts taking place every few minutes',
      'Mobile & online platform integration with live streams',
      'Customisable odds & limits',
    ],
    images: ['betgames_online.png', 'betgames_retail.png'],
  },
  {
    name: 'Roulette',
    url: 'roulette',
    icon: 'roulette',
    live: true,
    rng: true,
    description: [
      `Aardvark Technologies offer both RNG and live-action (streamed) roulette products 
      for partner bookmakers. Depending on business needs, such games can be offered with 
      fully customizable odds, multi layered table limits & other settings.`,
      `Live-action roulette streams can be supplied via 3 different quality streams (High, 
      Medium & Low) to reduce broadband bandwidth requirements in regions where internet 
      connections are less stable / plentiful.`,
    ],
    features: [
      'Bet types offered on numbers, thirds, halves, odd/even, colors, rows and French selections',
      'Progressive jackpots able to be offered on all spins',
      'Different display designs for RNG roulette: Modern or Classic',
      'Live-Action Roulette three different quality streams',
      'Unique audio & video interactions',
      'Statistics from last 1,000 spins',
      'Option to use French Bets',
      'Able to offer la Partage rule gaming',
      'Available via mobile & online platform',
    ],
    images: ['roulette_online.png', 'roulette_retail.png'],
  },
  {
    name: 'Casino Games',
    url: 'casino-games',
    icon: 'slots',
    live: true,
    rng: true,
    description: [
      `Over 500+ individual games are being offered with unique individual designs as part of 
      Aardvark's casino games package. Instant games or slot games are provided by some of the 
      world's leading brands such as AGT, Evolution, Pragmatic Play and Habanero.`,
      `Most of the game providers offer a progressive jackpot system, which can be easily set 
      up by your key account manager. Customize limits, frequency and size of the winnings - 
      anything you might need to encourage player engagement.`,
    ],
    features: [
      'Over 500+ individual games',
      'Exciting designs and animations',
      'Fast-paced games',
      'Available jackpot feature',
      'World famous product providers',
      'Exclusive for online',
    ],
    images: ['slots_online.png', 'slots_online-1.png'],
  },
  {
    name: 'Virtual Games',
    url: 'virtual-games',
    icon: 'virtual_football',
    live: false,
    rng: true,
    description: [
      `Aardvark offers Sport Radar's best of breed Virtual Football League with realistic 
      team names, odds and the fullest array of betting markets - all individually configurable.`,
      `Game video can either play out locally from a hard drive to eliminate reliance on 
      broadband or it can be streamed live. Whichever solution works best given shop set 
      up and location.`,
      `Players are fully engaged & enabled to place bets on these matches in the exact 
      same way that they do in real football matches.`,
      `Virtual football comes packed with competition mirroring major leagues and cups 
      that exist in reality.`,
    ],
    features: [
      'All main football betting markets available',
      'Option to combine selections as multiple bets',
      '100+ events per hour',
      'Progressive jackpot system',
      'Offline solution or web streaming of matches possible',
      'Mobile & online integration',
    ],
    images: ['vf_retail.png', 'vf_retail-1.png', 'vf_retail-2.png'],
  },
  {
    name: 'Keno',
    url: 'keno',
    icon: 'keno',
    live: false,
    rng: true,
    description: [
      `An old time favorite, Keno is a number based game and we are happy to present it as one of 
      the simplest to understand and most fun to play games around in the betting industry.`,
      `Aardvark's Keno is an RNG product with a smooth graphical design layout and offering a 
      unique two-bet system.`,
      `Player's select 1 to 10 numbers to be drawn from a total universe of 80 numbers and then 
      hope for the maximum number of selections to match from the 20 drawn. Players can either 
      win for exact matches (e.g. 10 balls from 10 predictions) or can win with supplementary 
      prizes (e.g. getting 8 balls from 10 predictions).`,
    ],
    features: [
      'Fully customizable odds, settings & limits',
      'Progressive jackpot system',
      'Hot & Cold balls & previous draw results',
      'Customizable draw times',
      'Mobile & Online integration',
      'Low Internet Bandwidth Usage',
    ],
    images: ['keno_online.png', 'keno_retail.png'],
  },
];
