
import { defineComponent } from 'vue';

// Data
import AboutData from '@/components/view/about/AboutData';

// Components
import TripleBlock from '@/components/global/TripleBlock.vue';

export default defineComponent({
  components: { TripleBlock },
  name: 'WhatMakesUs',
  data() {
    return {
      AboutData,
    };
  },
});
