
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

// Components
import Header from '@/components/global/Header.vue';
import Footer from '@/components/global/Footer.vue';
import Promo from '@/components/global/PromoPopup.vue';
import SlideShow from './components/global/SlideShow.vue';
// import Snow from '@/components/global/Snow.vue';

export default defineComponent({
  name: "AppHolder",
  components: {
    Header,
    SlideShow,
    Footer,
    Promo,
    // Snow,
  },
  data() {
    return {
      showPromo: false,
    };
  },
  mounted() {
    this.getPositions();

    const promoExpiration = new Date('2024-02-08T00:00:01Z');
    this.showPromo = promoExpiration > new Date();
  },
  methods: {
    ...mapActions({
      getPositions: 'getPositions',
    }),
  },
});
