import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e64c5ed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "career-message" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Perks = _resolveComponent("Perks")!
  const _component_Positions = _resolveComponent("Positions")!
  const _component_HRs = _resolveComponent("HRs")!
  const _component_TechStack = _resolveComponent("TechStack")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.CareerData.message), 1)
    ]),
    _createVNode(_component_Perks, {
      perks: _ctx.CareerData.perks
    }, null, 8, ["perks"]),
    _createVNode(_component_Positions, {
      show: 0,
      inverted: true
    }),
    _createVNode(_component_HRs, {
      hrList: _ctx.CareerData.hr
    }, null, 8, ["hrList"]),
    _createVNode(_component_TechStack, {
      perks: _ctx.CareerData.technologies
    }, null, 8, ["perks"])
  ]))
}