
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { IPosition } from "@/store/IState";

export default defineComponent({
  name: 'Positions',
  props: {
    show: {
      type: Number,
      required: false,
      default: 3, // 0 would be all
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    return {
      positions: computed(() => store.state.positions),
    };
  },
  computed: {
    positionsToShow(): IPosition[] {
      return this.positions.slice(0, this.show || Infinity);
    },
  },
  methods: {
    genPositionUrl(title: string, short: string): string {
      return `${title} ${short}`
        .replace(/,/g, '') // Replace all ','
        .replace(/ \/ /g, ' ') // Replace all ' / '
        .replace(/\//g, '') // Replace all '/'
        .replace(/ /g, '-') // Replace all ' '
        .toLowerCase();
    },
  },
});
