import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "homepage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OurSolutions = _resolveComponent("OurSolutions")!
  const _component_DesiredProducts = _resolveComponent("DesiredProducts")!
  const _component_DeliveringResults = _resolveComponent("DeliveringResults")!
  const _component_WhatsApp = _resolveComponent("WhatsApp")!
  const _component_Positions = _resolveComponent("Positions")!
  const _component_Partners = _resolveComponent("Partners")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OurSolutions),
    _createVNode(_component_DesiredProducts, { placement: 'home' }),
    _createVNode(_component_DeliveringResults),
    _createVNode(_component_WhatsApp),
    _createVNode(_component_Positions),
    _createVNode(_component_Partners)
  ]))
}