
import { defineComponent } from 'vue';

const PROMO_TIME = 'PROMO_TIME';

export default defineComponent({
  data() {
    return {
      delay: 100,
      show: false,
    };
  },
  computed: {
    localStorageEnabled() {
      const test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  mounted() {
    const setTime = localStorage.getItem(PROMO_TIME);
    if (!this.localStorageEnabled || !setTime) {
      this.prepareModal();
      return;
    }
    const day = 24 * 60 * 60 * 1000;

    if ((Date.now() - parseInt(setTime, 10)) > day) {
      localStorage.removeItem(PROMO_TIME);
      this.prepareModal();
    }
  },
  methods: {
    hideModal() {
      localStorage.setItem(PROMO_TIME, `${Date.now()}`);
      this.show = false;
    },
    prepareModal() {
      setTimeout(() => {
        this.show = true;
      }, this.delay);
    },
  },
});
