
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

// Data
import PositionData from '@/components/view/position/PositionData';

// Components
import { IPosition } from '@/store/IState';
import OurCulture from './position/OurCulture.vue';
import AboutPosition from './position/AboutPosition.vue';
import Colleague from './position/Colleague.vue';
import PositionPerks from './position/PositionPerks.vue';
import PositionForm from './position/PositionForm.vue';

// Interface

export default defineComponent({
  name: 'Position',
  components: {
    OurCulture,
    AboutPosition,
    Colleague,
    PositionPerks,
    PositionForm,
  },
  setup() {
    const store = useStore();

    return {
      positions: computed(() => store.state.positions),
    };
  },
  data() {
    return {
      PositionData,
    };
  },
  computed: {
    selectedPosition(): IPosition | undefined {
      const { position } = this.$route.params;

      return this.positions.find((p) => {
        const { title, short } = p;
        const generatedPath = `${title} ${short}`
          .replace(/,/g, '') // Replace all ','
          .replace(/ \/ /g, ' ') // Replace all ' / '
          .replace(/\//g, '') // Replace all '/'
          .replace(/ /g, '-') // Replace all ' '
          .toLowerCase();

        return generatedPath === position;
      });
    },
  },
});
