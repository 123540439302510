
import { defineComponent } from 'vue';
import OurSolutions from './homepage/OurSolutions.vue';
import DesiredProducts from './homepage/DesiredProducts.vue';
import DeliveringResults from './homepage/DeliveringResults.vue';
import WhatsApp from './homepage/WhatsApp.vue';
import Positions from './homepage/Positions.vue';
import Partners from './homepage/Partners.vue';

export default defineComponent({
  name: 'HomepageView',
  components: {
    OurSolutions,
    DesiredProducts,
    DeliveringResults,
    WhatsApp,
    Positions,
    Partners,
  },
});
